import { MutableRefObject, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import { Flavour, Question, QuestionResponse } from "../../../interface";
import {
  TitleSkeleton,
  ParagraphSkeleton,
  OneLineQuestionSkeleton,
} from "../../../components/Skeleton";
import Citations from "../../../components/Citations/Citations";
import classnames from "classnames";
import { QuestionBoxFullWidth } from "../../../components/QuestionBox/QuestionBoxFullWidth";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import { motion, useScroll, useTransform, useInView, circOut, cubicBezier, useMotionValueEvent } from "framer-motion"
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Pdf } from "../../../components/Pdf/pdf";
import { Video } from "../../../components/Video/video";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";

export const XThingsInform = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      questions,
      flavours
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
      questions: Question[];
      flavours: Flavour[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query, assets } = response;

    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [para3Done, setPara3Done] = useState(false);
    const [header1Done, setHeader1Done] = useState(false);
    const [header2Done, setHeader2Done] = useState(false);
    const [header3Done, setHeader3Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const informXThingsRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef)

    useEffect(() => {
      setNextTemplate(informXThingsRef);
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    //ANIMATION
    const { scrollYProgress: mainYProgress } = useScroll({
      target: informXThingsRef,
      offset: ["start center", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(mainYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(mainYProgress, [1, 0.6], ["90%", "100%"]);
    const backgroundY = useTransform(mainYProgress, [0.6, 1], ["0%", "30%"]);


    const { scrollYProgress: contentYProgress } = useScroll({
      target: informXThingsRef,
    });
    const { scrollYProgress: questionYProgress } = useScroll({
      target: informXThingsRef,
      offset: ["center end", "end center"],
    });

    const y = useTransform(contentYProgress, [0, 1], ["1%", document.body.clientHeight < 2200 ? "-60%" : "-60%"]);
    const questionY = useTransform(questionYProgress, [1, 0], ["-100%", "0%"]);
    // const questionY = useTransform(contentYProgress, [0, 1], ["100%", "-100%"]);

    useEffect(() => {
      if (para3Done && followUps.length > 0) {
        questionRef.current?.scrollIntoView({
          block: "start",
          inline: "center",
          behavior: "smooth",
        });
      }
    }, [para3Done, followUps.length > 0])
    //END ANIMATION

    return (
      <motion.div
        ref={informXThingsRef}
        id="xthings-inform"
        className="relative mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] -mt-[2.5rem] z-40"
        style={(flavours.length - 1) !== templateOrderIndex && questions.length > 1 ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress } : {}}

      >
        <div className="desktop:h-[250vh]">
          <div className={classnames(
            "desktop:sticky desktop:top-0 desktop:h-screen overflow-hidden mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]",
            {
              ["bg-white"]: version === "white",
              ["bg-grey-200"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}>
            <motion.div style={useMediaQuery('(min-width: 1280px)') ? { y: y } : {}}>
              <Grid
                customClasses={classnames(
                  "gap-x-4 relative desktop:!mx-0 rounded-t-[3.125rem]"
                )}
              >
                <div
                  id="xthings-inform-tagline-container"
                  className="z-30 mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-10 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
                >
                  <h2
                    id="xthings-inform-tagline"
                    className={classnames(
                      "mobile:text-[1rem] desktop:text-lg font-medium uppercase",
                      {
                        ["!text-text-greyHeader"]: version === "white" || version === "grey",
                        ["!text-white"]: version === "dark",
                      }
                    )}
                  >
                    {query}
                  </h2>
                </div>
                <div
                  id="xthings-inform-content"
                  className="h-1/2 mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-span-8 mobile:pt-[36px] desktop:pt-[2.25rem] desktop:pr-8 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:flex desktop:flex-col desktop:pb-[2.5rem]"
                >
                  {!content.header ? (
                    <TitleSkeleton rows={1} />
                  ) : (
                    <h3
                      id="xthings-inform-header"
                      className={classnames(
                        "mobile:text-4xl desktop:text-[40px] font-bold",
                        {
                          ["!text-text-greyHeader"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}
                    >
                      {content.header}
                    </h3>
                  )}
                </div>

                <div
                  id="xthings-inform-answer-content"
                  className="mobile:col-start-1 desktop:col-start-11 mobile:col-span-full desktop:col-end-24 mobile:pt-[36px] tablet:pt-[36px] desktop:pt-[36px] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:pb-[2.5rem]"
                >
                  <div className="grid desktop:grid-cols-13 gap-x-4">
                    <div className="mobile:col-start-1 mobile:col-span-full desktop:col-span-6 modile:row-start-1 ">
                      {!content.paragraphs[0] ? (
                        <OneLineQuestionSkeleton />
                      ) : (
                        <p
                          className={classnames(
                            "mobile:text-xl desktop:text-2xl",
                            {
                              ["!text-text-greyHeader"]: version === "white" || version === "grey",
                              ["!text-white"]: version === "dark",
                            }
                          )}
                        >
                          <TypewriterText
                            hasAllText
                            text={content.paragraphs[0].header}
                            type="strong"
                            isDone={header1Done}
                            setIsDone={setHeader1Done}
                          />
                        </p>
                      )}
                    </div>
                    <div
                      className={classnames(
                        "mobile:col-start-1 mobile:col-span-full desktop:col-span-6 mobile:row-start-2 desktop:row-start-2",
                        {
                          ["!text-text-greyText"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}
                    >
                      {!content.paragraphs[0]?.text ? (
                        <ParagraphSkeleton />
                      ) : (
                        header1Done && (
                          <TypewriterText
                            id="xthings-inform-paragraph-0"
                            className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 mobile:pt-[36px] desktop:pt-[1.375rem]"
                            hasAllText={content.paragraphs[0].done}
                            text={content.paragraphs[0].text}
                            isDone={para1Done}
                            setIsDone={setPara1Done}
                          />
                        )
                      )}
                    </div>

                    <div
                      className={classnames(
                        "mobile:col-start-1 desktop:col-start-8 desktop:col-span-6 mobile:col-span-full mobile:row-start-3 desktop:row-start-1 mobile:pt-[36px] desktop:pt-0",
                        {
                          ["!text-text-greyHeader"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}
                    >
                      {!content.paragraphs[1] ? (
                        <OneLineQuestionSkeleton />
                      ) : (
                        para1Done && (
                          <p
                            className={classnames(
                              "mobile:text-xl desktop:text-2xl",
                              {
                                ["!text-text-greyHeader"]: version === "white" || version === "grey",
                                ["!text-white"]: version === "dark",
                              }
                            )}
                          >
                            <TypewriterText
                              hasAllText
                              text={content.paragraphs[1].header}
                              type="strong"
                              isDone={header2Done}
                              setIsDone={setHeader2Done}
                            />
                          </p>
                        )
                      )}
                    </div>
                    <div
                      className={classnames(
                        "mobile:col-start-1 mobile:col-span-full desktop:col-start-8 desktop:col-span-6 mobile:row-start-4 desktop:row-start-2",
                        {
                          ["!text-text-greyText"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}
                    >
                      {!content.paragraphs[1]?.text ? (
                        <ParagraphSkeleton />
                      ) : (
                        header2Done && (
                          <TypewriterText
                            id="xthings-inform-paragraph-1"
                            className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 mobile:pt-[36px] desktop:pt-[1.375rem]"
                            hasAllText={content.paragraphs[1].done}
                            text={content.paragraphs[1].text}
                            isDone={para2Done}
                            setIsDone={setPara2Done}
                          />
                        )
                      )}
                    </div>

                    <div
                      className={classnames(
                        "mobile:col-start-1 mobile:col-span-full desktop:col-span-6 mobile:row-start-5 desktop:row-start-3 mobile:pt-[36px] desktop:pt-[2.25rem]",
                        {
                          ["!text-text-greyHeader"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}
                    >
                      {content.paragraphs[2] && para2Done && (
                        <p className="mobile:text-xl desktop:text-2xl">
                          <TypewriterText
                            hasAllText
                            text={content.paragraphs[2].header}
                            type="strong"
                            isDone={header3Done}
                            setIsDone={setHeader3Done}
                          />
                        </p>
                      )}
                    </div>
                    <div
                      className={classnames(
                        "mobile:col-start-1 mobile:col-span-full desktop:col-span-6 mobile:row-start-6 desktop:row-start-4",
                        {
                          ["!text-text-greyText"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}
                    >
                      {content.paragraphs[2]?.text && header3Done && (
                        <TypewriterText
                          id="xthings-inform-paragraph-2"
                          className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 mobile:pt-[36px] desktop:pt-[1.375rem]"
                          hasAllText={content.paragraphs[2].done}
                          text={content.paragraphs[2].text}
                          isDone={para3Done}
                          setIsDone={setPara3Done}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              {para2Done && <Citations metadata={response.metadata} version={version} />}
              {para2Done && <MediaLinksV2 assets={assets} display="fullwidth" version={version} />}
            </motion.div>

            {/* <div className="desktop:grid desktop:grid-cols-24 w-full absolute bottom-16">
              <div
                className={classNames("col-start-2 row-start-1 col-span-full", {
                  ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                    para3Done,
                  ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                    !para3Done,
                })}
              >
                {version === "white" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="white"
                  />
                )}
                {version === "dark" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="dark"
                  />
                )}
                {version === "grey" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="grey"
                  />
                )}
              </div>
            </div> */}
          </div>
        </div>
        <div className={classNames({
          ["!hidden"]: !para3Done,
          ["bg-white"]: version === "white",
          ["bg-grey-200"]: version === "grey",
          ["bg-grey-1000 text-white"]: version === "dark"
        })}>
          <motion.div
            ref={questionRef}
            style={useMediaQuery('(min-width: 1280px)') && para3Done && followUps.length > 0 ? { y: questionY } : {}}
            //style={para3Done && questionIsInView ? {y: "-100%", transition: "all 400ms ease-in"} : {y: "0%", visibility: "hidden", transition: "all 300ms ease-out"}}            
            className={classNames("mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] w-full z-40 mobile:relative desktop:absolute mobile:-mt-[3.125rem] desktop:-mt-0",
              {
                ["hidden"]: hideQuestion,
              }
            )}>
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para3Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </div>
      </motion.div>
    );
  }
);
