import {
  MutableRefObject,
  Ref,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { Grid, QuestionBoxFullWidth } from "../../../components";
import { TitleSkeleton, ParagraphSkeleton } from "../../../components/Skeleton";
import Citations from "../../../components/Citations/Citations";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import { Flavour, Question, QuestionResponse } from "../../../interface";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import MediaLinks from "../../../components/MediaLinks/MediaLinks";
import { motion, useScroll, useTransform, useInView, circOut, cubicBezier, useMotionValueEvent } from "framer-motion"
import useMediaQuery from "../../../hooks/useMediaQuery";
import { useChat } from '../../../context/chat-context';
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";

export const LongHero = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      questions,
      flavours,
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
      questions: Question[];
      flavours: Flavour[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const { resumed } = useChat();
    const { content, followUps, userQuestion, query, assets } = response;
    const [para1Done, setPara1Done] = useState(resumed || false);
    const [para2Done, setPara2Done] = useState(resumed || false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const heroLongRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef)

    useEffect(() => {
      setNextTemplate(heroLongRef);
    }, []);

    const { scrollYProgress: mainYProgress } = useScroll({
      target: heroLongRef,
      offset: ["start center", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(mainYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(mainYProgress, [1, 0.6], ["90%", "100%"]);
    const backgroundY = useTransform(mainYProgress, [0.6, 1], ["0%", "30%"]);

    const { scrollYProgress: contentYProgress } = useScroll({
      target: heroLongRef,
    });
    const { scrollYProgress: questionYProgress } = useScroll({
      target: heroLongRef,
      offset: ["center end", "end center"],
    });


    const y = useTransform(contentYProgress, [0, 1], ["1%", document.body.clientHeight < 2100 ? "-60%" : "-40%"]);
    const questionY = useTransform(questionYProgress, [1, 0], ["-100%", "0%"]);
    //const questionY = useTransform(contentYProgress, [0, 1], ["100%", "0%"]);

    useEffect(() => {
      if (para2Done && followUps.length > 0) {
        questionRef.current?.scrollIntoView({
          block: "start",
          inline: "center",
          behavior: "smooth",
        });
      }
    }, [para2Done, followUps.length > 0]);

    return (
      <div className="relative z-40">
        <motion.div
          ref={heroLongRef}
          id="long-hero"
          className={classNames("relative desktop:rounded-t-[1.5rem] z-40", {
            ["-mt-[2.5rem]mobile:rounded-t-3xl"]: templateOrderIndex !== 0,
          })}
          style={(flavours.length - 1) !== templateOrderIndex && questions.length > 1 ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress } : {}}
        >
          <div className="desktop:h-[200vh]">
            <div
              // style={{ backgroundImage: `url('${content?.imgURL}')` }}
              className="bg-gradient-to-b from-[#E0CCFF] from-25% to-white mobile:relative desktop:sticky mobile:h-full desktop:top-0 desktop:h-screen bg-no-repeat bg-center bg-cover mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]"
            >
              <div className="text-black h-full w-full mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] relative">
                <motion.div style={useMediaQuery('(min-width: 1280px)') && assets ? { y: y } : {}}>
                  <Grid customClasses="gap-x-4 relative h-full desktop:!mx-0 desktop:pb-[2.5rem] mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]">
                    <div
                      id="long-hero-tagline-content"
                      className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-22 mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] desktop:row-start-1 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
                    >
                      <h2
                        id="long-hero-tagline"
                        className="mobile:text-[1rem] desktop:text-lg font-medium uppercase"
                      >
                        {query}
                      </h2>
                    </div>
                    <div
                      id="long-hero-header-content"
                      className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-13 pt-[36px] desktop:row-start-2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
                    >
                      {!content.header ? (
                        <TitleSkeleton />
                      ) : (
                        <h3
                          id="long-hero-header"
                          className="text-text-greyHeader mobile:text-4xl desktop:text-[40px] font-bold"
                        >
                          {content.header}
                        </h3>
                      )}
                    </div>
                    <div

                      id="long-hero-subheading-content"
                      className="mobile:col-start-1 desktop:col-start-13 mobile:col-span-full desktop:col-end-24 pt-[36px] desktop:row-start-2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
                    >
                      {!content.tagline ? (
                        <ParagraphSkeleton />
                      ) : (
                        <h4 id="long-hero-subheading" className="text-text-greyText font-light mobile:text-xl desktop:text-2xl tablet:text-xl">
                          {content.tagline}
                        </h4>
                      )}
                    </div>

                    <div
                      id="long-hero-paragraph-content-1"
                      className="text-text-greyText desktop:min-h-[200px] mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-12 mobile:pt-[36px] tablet:pt-[36px] desktop:pt-[1.875rem] desktop:row-start-3 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
                    >
                      {!content.paragraphs[0] ? (
                        <ParagraphSkeleton />
                      ) : (
                        <TypewriterText
                          id="long-hero-paragraph-1"
                          className="desktop:text-lg font-light mobile:text-sm tablet:text-sm"
                          hasAllText={content.paragraphs[0].done}
                          text={
                            content.paragraphs[0].text[
                              content.paragraphs[0].text.length - 1
                            ] === "."
                              ? content.paragraphs[0].text
                              : `${content.paragraphs[0].text}.`
                          }
                          isDone={para1Done}
                          setIsDone={setPara1Done}
                        />
                      )}
                    </div>

                    <div
                      id="long-hero-paragraph-content-2"
                      className="text-text-greyText desktop:min-h-[200px] mobile:col-start-1 desktop:col-start-13 mobile:col-span-full desktop:col-end-24 mobile:pt-[22px] tablet:pt-[1.375rem] desktop:pt-[1.875rem] desktop:row-start-3 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
                    >
                      {!content.paragraphs[1] ? (
                        <ParagraphSkeleton />
                      ) : (
                        para1Done && (
                          <TypewriterText
                            id="long-hero-paragraph-2"
                            className="desktop:text-lg font-light  mobile:text-sm tablet:text-sm"
                            text={content.paragraphs[1].text}
                            hasAllText={content.paragraphs[1].done}
                            isDone={para2Done}
                            setIsDone={setPara2Done}
                          />
                        )
                      )}
                      {/* {para2Done && <MediaLinks assets={assets} />} */}
                    </div>
                  </Grid>
                  {para2Done && <Citations metadata={response.metadata} version="light" />}
                  {para2Done && <MediaLinksV2 assets={assets} display="fullwidth" version="light" />}

                </motion.div>
                {/* <div className="desktop:grid desktop:grid-cols-24 absolute bottom-16 w-full">
                    <div
                      id="button-scroll-container"
                      className="desktop:col-start-2 desktop:col-end-12 desktop:row-start-1"
                    >
                      <div
                        className={classNames("mobile:hidden desktop:block", {
                          ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                            para2Done,
                          ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                            !para2Done,
                        })}
                      >
                        <ButtonScrollDown
                          customClasses="relative"
                          versionColour="image"
                        />
                      </div>
                    </div>                  
                </div>                */}
              </div>
            </div>
          </div>
        </motion.div>

        <div className={classNames({ ["!hidden"]: !para2Done })}>
          <motion.div
            ref={questionRef}
            style={useMediaQuery('(min-width: 1280px)') && para2Done && followUps.length > 0 ? { y: questionY } : {}}
            //style={para2Done && questionIsInView ? { y: "-100%", transition: "all 400ms ease-in" } : { y: "0%", visibility: "hidden", transition: "all 300ms ease-out" }}
            className={classNames("w-full mobile:relative desktop:absolute z-40 mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] mobile:-mt-[3.125rem] desktop:-mt-0",
              {
                ["!hidden"]: hideQuestion,
              }
            )} >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para2Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </div>


        {/* MOBILE VERSION */}
        {/* <div className="desktop:hidden mobile:block">
            <div ref={mobileQuestionRef}>
                <div className={classNames("w-full relative z-40 rounded-t-[3.125rem] -mt-[3.125rem]",
                  {
                    ["hidden"]: hideQuestion,
                  }
                )}>
                  <QuestionBoxFullWidth
                    templateOrderIndex={templateOrderIndex}
                    style="lightTxt"
                    backgroundColour="background-brand"
                    followupQuestions={followUps}
                    isLoading={!para2Done}
                    onSubmit={askQuestion}
                    giveFeedback={giveFeedback}
                    optionQuestion={userQuestion}
                    nextTemplate={nextTemplateRef}
                    sethideQuestion={setHideQuestions}
                    customMargin="!mx-0"
                  />
              </div>
            </div>
        </div> */}
      </div>
    );
  }
);
