import React from 'react';
import { MutableRefObject, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid, QuestionBoxFullWidth } from "../../components";
import { QuestionResponse } from "../../interface";
import { TitleSkeleton } from "../../components/Skeleton";
import classnames from "classnames";
import { ButtonScrollDown } from "../../components/Button/ButtonScrollDown";
import TypewriterText from "../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import { motion, useInView } from "framer-motion"

export const Single = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion } = response;
    const [para1Done, setPara1Done] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const [hideQuestion, setHideQuestions] = useState(false);
    const questionRef = useRef(null);
    const questionIsInView = useInView(questionRef, { once: true })

    useEffect(() => {
      setNextTemplate(ref);
    }, []);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    console.log(
      "template: Single",
      `height: ${document.getElementById("single")?.getBoundingClientRect().height}`,
      `width: ${document.getElementById("single")?.getBoundingClientRect().width}`
    );

    return (
      <div ref={ref} id="single" className="relative">
        {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
        <Grid
          testId="grid"
          customClasses={classnames(
            "gap-x-4 relative mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] -mt-[2.5rem]",
            {
              ["bg-white"]: version === "white",
              ["bg-grey-200"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}
        >
          <div
            id="single-answer-container"
            className="h-full mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-24 flex flex-col row-start-1 mobile:pb-[4.5rem] desktop:pb-[8.375rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <div>
              <h2
                id="tagline"
                className="mobile:text-base desktop:text-xl font-semibold uppercase"
              >
                Question from “ask a follow up” would appear here in this style?
              </h2>
              <div className="mobile:pt-[1.5rem] desktop:pt-[1.875rem]">
                {!content.paragraphs[0] ? (
                  <TitleSkeleton rows={1} />
                ) : (
                  <TypewriterText
                    id="single-answer"
                    className="!font-default text-2xl"
                    hasAllText={content.paragraphs[0].done}
                    text={content.paragraphs[0].text}
                    isDone={para1Done}
                    setIsDone={setPara1Done}
                  />
                )}
              </div>
              <div
                className={classNames("mobile:hidden desktop:block pt-16", {
                  ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                    para1Done,
                  ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                    !para1Done,
                })}
              >
                {version === "white" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="white"
                  />
                )}
                {version === "dark" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="dark"
                  />
                )}
                {version === "grey" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="grey"
                  />
                )}
              </div>
            </div>
          </div>
          <motion.div
            ref={questionRef}
            id="question-container"
            style={para1Done && questionIsInView ? { y: "0%", transition: "all 300ms ease-in" } : { y: "100%", visibility: "hidden" }}
            className={classNames(
              "col-span-full mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
              {
                ["hidden"]: hideQuestion,
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para1Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </Grid>
      </div>
    );
  }
);
